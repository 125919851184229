import FrameDef from './FrameDef';

export default class RectFrameDef extends FrameDef{
  constructor(wid, hei, colspan){
    var points = [{x: 0, y: 0}, {x:0, y: hei}, {x: wid, y: hei}, {x: wid, y: 0}]
    super(points, colspan);
    this._wid = wid;
    this._hei = hei;
  }

  get width(){
    return this._wid;
  }

  get height(){
    return this._hei;
  }
}