var createjs = window.createjs;

export default class WaterMark extends createjs.Container {
  constructor(stage){
    super(stage);

    this._text = new createjs.Text(this._rawText, "15px komika_handregular", this.color);
    this._text.text = "storytool.co";
    this._selected = false;
    this._mouseOver = false;

    this._backing = new createjs.Shape();

    this._text.x = 10;
    this._text.y = 10;

    this.addChild(this._backing);
    this.addChild(this._text);
  }

  draw = (ctx, ignoreCache) => {
    var b = this._text.getBounds();

    this.componentHeight = b.height + 20;
    this.componentWidth = b.width + 20;

    var backing = this._backing;
    backing.graphics.clear();
    backing.graphics.setStrokeStyle(1);
    backing.graphics.beginStroke("#000000");
    backing.graphics.beginFill("#FFFFFF");

    backing.graphics.drawRect(0, 0, this.componentWidth, this.componentHeight);

    return super.draw(ctx, ignoreCache);
  }
}