
var createjs = window.createjs;

export default class TextOverlay extends createjs.Container{
  constructor(stage){
    super();

    this._stage = stage;
    this._resizeDragger = new createjs.Shape();
    this._resizeDragger.visible = false;

    this.body = new createjs.Container();
    this.backing = new createjs.Container();
    
    this._rawText = "Your text here";
    this._text = new createjs.Text(this._rawText, "15px komika_handregular", this.color);
    this._selected = false;
    this._mouseOver = false;
    
    this._textHeight = 0;
    this._textWidth = 0;

    this._desiredWidth = undefined;
    this._desiredHeight = undefined;
    this._minHeight = 100;
    this._minWidth = 100;
    this._componentHeight = 0;
    this._componentWidth = 0;

    var text = this._text;
    text.textBaseline = "top";
    text.textAlign = "center";
    text.lineWidth = 150;
    text.lineHeight = 18;

    this.body.addChild(this._text);

    this.addChild(this.backing);
    this.addChild(this.body);
    this.addChild(this._resizeDragger);

    this._setupRolloverHandlers(this, this);
    this._setupDragHandlers(this.body, this);
    this._setupResizeHandlers(this._resizeDragger, this);
  }

  set text(text){
    this._rawText = text;
    this.redraw();
  }

  get text(){
    return this._rawText;
  }

  get textHeight(){
    return this._textHeight;
  }

  get textWidth(){
    return this._textWidth;
  }

  get componentWidth(){
    return this._componentWidth;
  }

  get componentHeight(){
    return this._componentHeight;
  }

  set desiredWidth(width){
    this._desiredWidth = width;
  }

  set desiredHeight(height){
    this._desiredHeight = height;
  }

  get selected(){
    return this._selected;
  }

  set selected(yesno){
    if(yesno !== this._selected){
      this._selected = yesno;
      this._resizeDragger.visible = yesno;
      this.redraw();  
    }
  }

  get color(){
    if(this.selected){
      return "#ff0000";
    }else if(this._mouseOver){
      return "#ff0000";
    }else{
      return "#000000";
    }
  }

  drawText = () => {
    var text = this._text;
    text.text = this._rawText;
    text.color = this.color;
    text.y = -this.textHeight / 2;
  }

  computeTextBounds = () => {
    var text = this._text;
    var b = text.getBounds();

    if(b != null){
      this._textHeight = b.height;
      this._textWidth = b.width;
    }
  }

  redraw = () => {
    this.computeTextBounds();
    this.drawText();
    this._stage.update();
  }

  _setupDragHandlers = (trigger, target) => {
    var initialX = 0;
    var initialY = 0;

    trigger.on("mousedown", function(evt){
      var point = target.parent.globalToLocal(evt.stageX, evt.stageY);

      initialX = point.x - target.x;
      initialY = point.y - target.y;
    });

    trigger.on("pressmove", function(evt){
      var point = target.parent.globalToLocal(evt.stageX, evt.stageY);

      target.x = point.x - initialX;
      target.y = point.y - initialY;
      target._stage.update();
    });
  }

  _setupResizeHandlers = (shape, self) => {

    shape.on("pressmove", function(evt){
      var localPoint = self.globalToLocal(evt.stageX, evt.stageY);

      if(localPoint.x > self._minWidth / 2){
        self._desiredWidth = localPoint.x * 2;
      }

      if(localPoint.y > self._minHeight / 2){
        self._desiredHeight = localPoint.y * 2;
      }

      self.redraw();
    });
  }

  _setupRolloverHandlers = (shape, self) => {
    shape.on("rollover", function(evt){
      self._mouseOver = true;
      self.redraw();
    });

    shape.on("rollout", function(evt){
      self._mouseOver = false;
      self.redraw();
    });
  }
}