var createjs = window.createjs;

export default class TakePicture extends createjs.Container{
  constructor(stage, videoWindow, callback){
    super();

    this._stage = stage;
    this._callback = callback;
    this._video = videoWindow;
    this._videoBmp = new createjs.Bitmap(this._video);

    var self = this;

    this._video.addEventListener('canplay', (evt) => {
      
      this.addChild(this._videoBmp);
      self._setupCaptureButton();


      self.tickListener = createjs.Ticker.on("tick", () => {
        stage.update();
      });

    }, false);
  }

  showPreview = () => {
    var self = this;
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
          .then(function(stream) {
            self._mediaStream = stream;
            self._video.srcObject = stream;
            self._video.play();
          })
          .catch(function(err) {
            console.log("An error occurred: " + err);
          });
  }

  takePicture = () => {
    return this._pictureData();
  }

  releaseCamera = () => {
    if(this._mediaStream){
      createjs.Ticker.off("tick", this.tickListener);

      var tracks = this._mediaStream.getTracks();
      for(var i = 0; i < tracks.length; i++){
        tracks[i].stop();
      }
    }
  }

  _handleTakePicture = () => {
    this._callback(this._pictureData());
  }

  _pictureData = () => {
    this._videoBmp.cache(0, 0, this._video.videoWidth, this._video.videoHeight);
    var picture = this._videoBmp.bitmapCache.getCacheDataURL();
    this._videoBmp.uncache();

    return picture;
  }

  _setupCaptureButton = () => {
    this._captureButton = new createjs.Shape();
    var captureBtn = this._captureButton;
    captureBtn.graphics.beginFill("#ff0000").drawRect(0, 0, 200, 50);
    captureBtn.x = 250;
    captureBtn.y = 400;

    captureBtn.addEventListener("click", this._handleTakePicture);

    this.addChild(captureBtn);
  }
}