import React from 'react';
import './ComicBuilderCanvas.css';
import ComicStrip from './components/ComicStrip';
import ComicFrame from './components/ComicFrame';
import ComicImage from './components/ComicImage';
import TakePicture from './components/TakePicture';
import RectFrameDef from './components/frameDef/RectFrameDef';

import { Button, Dropdown, Navbar, ButtonToolbar } from 'react-bootstrap';
import ArtBoard from './components/ArtBoard';
import SalesPitch from './SalesPitch';

class ComicBuilderCanvas extends React.Component {

  constructor() {
    super();
    this._comicStrip = null;
    this._stage = null;
    this.state = { tool: '', loaded: false, brightness: 0, contrast: 0, overlayText: "" }
    this._images = [];
  }

  componentDidMount() {
    this._doPreload();
  }

  get comicStrip() {
    return this._comicStrip;
  }

  get artboard() {
    return this._artboard;
  }

  get stage() {
    return this._stage;
  }

  _doPreload = () => {

    var createjs = window.createjs;

    var manifest = [
      { src: 'Komika_Hand-webfont.woff', id: "f1", type: "font" },
      { src: 'images/childrenvictory.jpg', id: "testFrame1" },
      { src: 'images/CoderAlone.jpg', id: "testFrame2" },
      { src: 'images/dogone.jpg', id: "testFrame3" },
    ]

    var postManifest = [
      { src: 'images/catsix.jpg', id: "testFrame4" },
      { src: 'images/catseven.jpg', id: "testFrame5" },
      { src: 'images/dogtwo.jpg', id: "testFrame6" },
    ]

    var loader = new createjs.LoadQueue();
    var postLoader = new createjs.LoadQueue();
    postLoader.on('fileload', (event) => {
      var item = event.item;
      if (item.type === "image") {
        self._images.push(event.result);
      }
    }, this);

    var self = this;
    loader.on('fileload', (event) => {
      var item = event.item;
      if (item.type === "image") {
        self._images.push(event.result);
      }
    }, this);

    loader.on('complete', () => {
      self.setState({ loaded: true });
      self._init();
      var frameDefs = [
        new RectFrameDef(250, 300, 1),
        new RectFrameDef(250, 300, 1),
        new RectFrameDef(250, 300, 1)
      ]
      this._setDimensions(1000, 500, 1, 3, frameDefs);
      postLoader.loadManifest(postManifest);
    }, this);

    loader.loadManifest(manifest);
  }

  _init = () => {
    var createjs = window.createjs;

    this._stage = new createjs.Stage("demoCanvas");
    var stage = this.stage;
    stage.cursor = "pointer";

    this._artboard = new ArtBoard(stage);
    this._comicStrip = new ComicStrip(stage, 1, 3);
    this._artboard.comicStrip = this._comicStrip;
    this._artboard.scale = .75;

    var comicStrip = this._comicStrip;

    comicStrip.addEventListener("frameSelected", this._handleFrameSelected);
    comicStrip.addEventListener("overlaySelected", this._handleOverlaySelected);
    comicStrip.addEventListener("clearSelection", this._handleClearSelection);

    stage.enableMouseOver(5);

    stage.canvas.width = window.innerWidth;
    stage.canvas.height = window.innerHeight;

    stage.on("stagemousedown", function (evt) {
      if (!evt.relatedTarget) {
        comicStrip.removeSelection();
      }
    });

    document.addEventListener("paste", this._handlePasteEvent);
    stage.addChild(this._artboard);

    var self = this;

    window.addEventListener('resize', () => {
      var stage = self._stage;
      var artboard = self.artboard;

      stage.canvas.width = window.innerWidth;
      stage.canvas.height = window.innerHeight;

      artboard.x = (stage.canvas.width / 2);
      artboard.y = (stage.canvas.height / 2);

      stage.update();
    }, false);

    var overlay = comicStrip.addBubble();
    overlay.text = 'Victory!!!';
    overlay.x = -60;
    overlay.y = 240;
    overlay.desiredWidth = 200;
    overlay.desiredHeight = 100;
    overlay._tail.tailEndY = -100;
    overlay._tail.redraw();
  }

  _setDimensions = (wid, hei, rows, cols, frameDefs) => {

    var stage = this.stage;
    var comicStrip = this.comicStrip;
    var artboard = this.artboard;

    artboard.setDimensions(wid, hei);
    comicStrip.setDimensions(rows, cols);
    comicStrip.removeAllFrames();

    for (var i = 0; i < frameDefs.length; i++) {
      var frameDef = frameDefs[i];
      var comicFrame = new ComicFrame(stage, frameDef, 3);
      var imageNum = i % this._images.length;
      comicFrame.image = new ComicImage(stage, this._images[imageNum]
        , frameDef.width + 50, frameDef.height + 50);
      comicStrip.addFrame(comicFrame);
    }

    var artboard = this._artboard;
    artboard.regX = artboard.width / 2;
    artboard.regY = artboard.height / 2;

    artboard.x = stage.canvas.width / 2;
    artboard.y = stage.canvas.height / 2;
    artboard.positionComicStrip();

    stage.update();
  }

  _handleFrameSelected = (event) => {
    var comicStrip = this._comicStrip;
    if (comicStrip.selectedFrame) {
      var image = comicStrip.selectedFrame.image;
      this.setState({ tool: 'frame', brightness: image.brightness, contrast: image.contrast });
    }
  }

  _handleOverlaySelected = (event) => {
    var comicStrip = this._comicStrip;
    if (comicStrip.selectedOverlay) {
      var overlay = comicStrip.selectedOverlay;
      this.setState({ tool: 'overlay', overlayText: overlay.text });
    }
  }

  _handleClearSelection = (event) => {
    this.setState({ tool: '' });
  }

  _toolsmenu = () => {
    if (this.state.tool === 'overlay') {
      return this._overlayMenu();
    } else if (this.state.tool === 'frame') {
      return this._imageMenu();
    }

    return (
      <Navbar>
      </Navbar>
    );
  }

  _overlayMenu = () => {
    return (
      <Navbar className='toolmenu-2'>
        <textarea className="mr-2" onChange={this._handleTextInputChange} value={this.state.overlayText}></textarea>
        <Button variant='brandred' onClick={this._removeOverlay}>Remove</Button>
      </Navbar>
    )
  }

  _imageMenu = () => {
    return (
      <Navbar className='toolmenu-2'>
        <Dropdown className='mr-2'>
          <Dropdown.Toggle variant="brandred">Adjust Image</Dropdown.Toggle>
          <Dropdown.Menu className='p-2'>
            <span>
              Brightness
              <input type="range" step=".01" min="-.5" max=".5" value={this.state.brightness} onChange={this._handleBrightnessAdjust}></input>
            </span>
            <span>
              Contrast
              <input type="range" step=".01" min="-.5" max=".5" value={this.state.contrast} onChange={this._handleContrastAdjust}></input>
            </span>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className='mr-2'>
          <Dropdown.Toggle variant="brandred">Change Image</Dropdown.Toggle>
          <Dropdown.Menu className='p-2'>
            <input className="button" onChange={this._handleFileSelect} accept='image/*' type="file" id="files" name="files[]" multiple />
            <Button className="button mt-2" variant="brandred" onClick={this._takePicture}>Take Picture</Button><br />
            <div className="mt-1">Use copy and paste to paste images into frames.</div>
            <span className="mt-1">Need images? Try &nbsp;
              <a href='https://pixabay.com/' target='_blank'>Pixabay</a> or <a href='https://unsplash.com' target='_blank'>Unsplash</a>&nbsp;
              for royalty free photos you can use in your comics.
            </span>
          </Dropdown.Menu>
        </Dropdown>
        <Button variant='secondary' onClick={this._enableDisableImagePositionAdjust}>Adjust Image Position and Size</Button>
      </Navbar>
    )
  }

  _mainMenu = () => {
    return (
      <Navbar className='toolmenu' bg="black">
        <select className='mr-2' onChange={this._handleRCChangeEvent} defaultValue="1by3">
          {/* <option value="google-sheets-header">Google Sheets Header</option> */}
          <option value="1by1">1x1</option>
          <option value="1by2">1x2</option>
          <option value="1by3">1x3</option>
          <option value="1by4">1x4</option>
          <option value="2by2">2x2</option>
          <option value="3by2">3x2</option>
          <option value="2-1-2">2-1-2</option>
        </select>
        <Button variant='brandred' className="mr-2" onClick={() => this._addOverlay('bubble')}>Add Bubble</Button>
        <Button variant='brandred' className="mr-2" onClick={() => this._addOverlay('caption')}>Add Caption</Button>
        <Button variant='brandred' className="mr-2" onClick={() => this._addOverlay('yellowcaption')}>Add Yellow Caption</Button>
        <select className='mr-2' onChange={this._handleScaleSelect} defaultValue="90">
          <option value="120">120%</option>
          <option value="100">100%</option>
          <option value="90">90%</option>
          <option value="75">75%</option>
          <option value="60">60%</option>
          <option value="50">50%</option>
          <option value="25">25%</option>
        </select>
        <span className="fontloader">&nbsp;</span>
        <Navbar.Collapse className="justify-content-end">
          <Dropdown className='mr-4 float-right'>
            <Dropdown.Toggle variant='brandyellow'>Save Comic</Dropdown.Toggle>
            <Dropdown.Menu className='p-2'>
              <Button variant='brandred' onClick={() => this._saveComic()}>With Watermark</Button>
              {/* <SalesPitch imageDataCallback={this._imageData} /> */}
          </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar >
    );
  }

  _addOverlay = (type) => {
    if (this.comicStrip) {
      switch (type) {
        case "bubble":
          this.comicStrip.addBubble();
          break;
        case "caption":
          this.comicStrip.addCaption();
          break;
        case "yellowcaption":
          this.comicStrip.addCaption("#F9C100");
          break;
      }
    }
  }

  _handleRCChangeEvent = (event) => {
    switch (event.target.value) {
      case "google-sheets-header":
        var frameDefs = [
          new RectFrameDef(380, 180, 1),
          new RectFrameDef(380, 180, 1)
        ]
        this._setDimensions(800, 200, 1, 2, frameDefs);
        break;
      case "1by1":
        var frameDefs = [new RectFrameDef(350, 350, 1)]
        this._setDimensions(600, 600, 1, 1, frameDefs);
        break;
      case "1by2":
        var frameDefs = [
          new RectFrameDef(250, 300, 1),
          new RectFrameDef(250, 300, 1)
        ];
        this._setDimensions(1000, 500, 1, 2, frameDefs);
        break;
      case "1by3":
        var frameDefs = [
          new RectFrameDef(250, 300, 1),
          new RectFrameDef(250, 300, 1),
          new RectFrameDef(250, 300, 1)
        ];
        this._setDimensions(1000, 500, 1, 3, frameDefs);
        break;
      case "1by4":
        var wid = 250;
        var hei = 300;
        var frameDefs = [
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1)
        ];
        this._setDimensions(1400, 600, 1, 4, frameDefs);
        break;
      case "2by2":
        var wid = 200;
        var hei = 200;
        var frameDefs = [
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1)
        ];
        this._setDimensions(600, 600, 2, 2, frameDefs);
        break;
      case "3by2":
        var wid = 200;
        var hei = 200;
        var frameDefs = [
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1)
        ];
        this._setDimensions(800, 600, 2, 3, frameDefs);
        break;
      case "2-1-2":
        var wid = 300;
        var hei = 120;
        var frameDefs = [
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid * 2 + 10, hei * 2, 2),
          new RectFrameDef(wid, hei, 1),
          new RectFrameDef(wid, hei, 1)
        ];
        this._setDimensions(1100, 900, 3, 2, frameDefs);
        break;
    }
  }

  _saveComic = (event) => {
    var comicStrip = this.comicStrip;
    comicStrip.removeSelection();
    var dataUrl = comicStrip.bitmapData;
    window.download(dataUrl, "ComicStrip.png", "image/png");
  }

  _imageData = () => {
    var comicStrip = this.comicStrip;
    comicStrip.removeSelection();
    comicStrip.hideWatermark();
    var dataUrl = comicStrip.bitmapData;
    comicStrip.showWatermark();
    return dataUrl;
  }

  _saveComicWithoutWatermark = (event) => {
    var dataUrl = this._imageData();
    window.download(dataUrl, "ComicStrip.png", "image/png");
  }

  _handleFileSelect = (event) => {
    var input = event.target;
    var comicStrip = this.comicStrip;
    var stage = this.stage;

    var reader = new FileReader();
    reader.onload = function () {
      var dataURL = reader.result;
      if (comicStrip.selectedFrame != null) {
        comicStrip.selectedFrame.image.src = dataURL;
        stage.update();
      }
    };

    reader.readAsDataURL(input.files[0]);
  }

  _takePicture = (event) => {
    var stage = this._stage;
    var comicStrip = this._comicStrip;
    var video = document.createElement('video');
    var pictureTaker = new TakePicture(stage, video, (pictureData) => {
      pictureTaker.releaseCamera();
      stage.removeChild(pictureTaker);
      stage.update();

      if (comicStrip.selectedFrame) {

        comicStrip.selectedFrame.image.src = pictureData;
      }
    });

    pictureTaker.showPreview();
    stage.addChild(pictureTaker);
  }

  _handlePasteEvent = (event) => {
    this._retrieveImageFromClipboardAsDataUrl(event, (dataURL) => {
      var comicStrip = this.comicStrip;
      if (comicStrip.selectedFrame != null) {
        comicStrip.selectedFrame.image.src = dataURL;
      }
    });
  }

  _retrieveImageFromClipboardAsDataUrl(pasteEvent, callback) {
    if (pasteEvent.clipboardData === false) {
      if (typeof (callback) === "function") {
        callback(undefined);
      }
    };

    var items = pasteEvent.clipboardData.items;

    if (items === undefined) {
      if (typeof (callback) == "function") {
        callback(undefined);
      }
    };

    for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === -1) continue;
      var blob = items[i].getAsFile();

      var reader = new FileReader();
      reader.onload = (evt) => {
        if (typeof (callback) == "function") {
          callback(evt.target.result);
        }
      }

      reader.readAsDataURL(blob);
    }
  }

  _handleTextInputChange = (event) => {
    var comicStrip = this.comicStrip;
    if (comicStrip && comicStrip.selectedOverlay) {
      comicStrip.selectedOverlay.text = event.target.value;
      this.setState({ overlayText: event.target.value });
    }
  }

  _handleBrightnessAdjust = (event) => {
    this.setState({ brightness: event.target.value });
    var comicStrip = this.comicStrip;
    if (comicStrip && comicStrip.selectedFrame) {
      comicStrip.selectedFrame.image.brightness = event.target.value;
    }
  }

  _handleContrastAdjust = (event) => {
    this.setState({ contrast: event.target.value });
    var comicStrip = this.comicStrip;
    if (comicStrip && comicStrip.selectedFrame) {
      comicStrip.selectedFrame.image.contrast = event.target.value;
    }
  }

  _enableDisableImagePositionAdjust = (event) => {
    var comicStrip = this.comicStrip;
    if (comicStrip && comicStrip.selectedFrame) {
      comicStrip.selectedFrame.maskEnabled = !comicStrip.selectedFrame.maskEnabled;
    }
  }

  _removeOverlay = () => {
    var comicStrip = this.comicStrip;
    if (comicStrip && comicStrip.selectedOverlay) {
      comicStrip.removeOverlay(comicStrip.selectedOverlay);
    }
  }

  _handleScaleSelect = (event) => {
    var value = event.target.value / 100;
    this.artboard.scale = value;
    this.stage.update();
  }

  _loadingindicator = () => {
    if(this.state.loaded == false){
      return (<div className="loadingindicator">
        Loading
      </div>);
    }else{
      return null;
    }
  }

  render() {
    return (
      <div>
        {this._mainMenu()}
        <div className="toolcontainer">
          {this._toolsmenu()}
        </div>
        {this._loadingindicator()}
        <canvas id="demoCanvas"></canvas>
      </div>
    );
  }
}

export default ComicBuilderCanvas;