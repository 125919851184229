

export default class FrameDef{
  constructor(points, colspan){
    this._points = points;
    this._colspan = colspan;
    this._wid  = 0;
    this._hei = 0;
  }

  get points(){
    return this._points;
  }

  get colSpan(){
    return this._colspan;
  }

  get width(){
    throw "Override width in derived class";
  }

  get height(){
    throw "Override height in derived class";
  }
}