import TextOverlay from './TextOverlay.js';
import BubbleTail from './BubbleTail.js';

var createjs = window.createjs;

export default class ComicBubble extends TextOverlay {
  constructor(stage){
    super(stage);

    this._stage = stage;

    this._tail = new BubbleTail(this._stage);
    this._backing = new createjs.Shape();
    this._stroke = new createjs.Shape();

    this._componentWidth = 0;
    this._componentHeight = 0;

    this.backing.addChildAt(this._stroke, 0);
    this.backing.addChildAt(this._tail, 1);
    this.body.addChildAt(this._backing, 0);

    this._setupRolloverHandlers(this, this);

    this.redraw();
  }

  set selected(yesno){
    if(yesno !== this._selected){
      this._selected = yesno;
      this._resizeDragger.visible = yesno;
      this._tail.selected = yesno;
      this.redraw();  
    }
  }

  redraw = () => {
    this.computeTextBounds();
    this.drawText();
    
    var backing = this._backing;
    backing.graphics.clear();
    backing.graphics.beginFill("#ffffff");

    var stroke = this._stroke;
    stroke.graphics.clear();
    stroke.graphics.setStrokeStyle(2);
    stroke.graphics.beginStroke(this.color);
    
    var padding = 20;

    var computedHeight = Math.max(100, this.textHeight * Math.sqrt(2) + padding);
    var computedWidth = Math.max(100, this.textWidth * Math.sqrt(2) + padding);

    if(this._desiredWidth){
      this._componentWidth = this._desiredWidth;
      this._text.lineWidth = this._desiredWidth / Math.sqrt(2);
    }else{
      this._componentWidth = computedWidth;
    }

    if(this._desiredHeight && (this._desiredHeight / Math.sqrt(2)) > this.textHeight){
      this._componentHeight = this._desiredHeight;
    }else{
      this._componentHeight = computedHeight;
    }

    backing.y = -(this.componentHeight / 2);
    backing.x = -(this.componentWidth / 2);
    stroke.y = -(this.componentHeight / 2);
    stroke.x = -(this.componentWidth / 2);

    this._text.y = -this.textHeight / 2;

    backing.graphics.drawEllipse(0, 0, this.componentWidth, this.componentHeight);
    stroke.graphics.drawEllipse(0, 0, this.componentWidth, this.componentHeight);

    this.drawResizeDragger();
    this._stage.update();
  }

  drawResizeDragger = () => {
    var resizeDragger = this._resizeDragger;
    resizeDragger.graphics.beginFill('#ff0000');
    resizeDragger.graphics.drawRect(0, 0, 10, 10);

    resizeDragger.x = this.componentWidth / 2;
    resizeDragger.y = this.componentHeight / 2;
  }

  _setupRolloverHandlers = (shape) => {
    var self = this;
    shape.on("rollover", function(evt){
      self._mouseOver = true;
      self._tail.mouseOver = true;
      self.redraw();
    });

    shape.on("rollout", function(evt){
      self._mouseOver = false;
      self._tail.mouseOver = false;
      self.redraw();
    });
  }
}