import FrameDef from './frameDef/FrameDef';

var createjs = window.createjs;

export default class ComicFrame extends createjs.Container {
  constructor(stage, frameDef, borderWeight) {
    super();
    this._stage = stage;
    this._frameDef = frameDef;
    this._borderWeight = borderWeight;
    this.componentWidth = frameDef.width;
    this.componentHeight = frameDef.height;
    this._maskEnabled = true;
    this._selected = false;
    this._cache = false;
    this._selectShadow = new createjs.Shadow("#FF0000", 0, 0, 5);

    this._setupMask();
    this._drawBorder();
  }

  set image(image) {
    if (this._image != null) {
      this.removeChild(this._image);
    }

    image.x = (this.componentWidth - image.componentWidth) / 2;
    image.y = (this.componentHeight - image.componentHeight) / 2;
    this._image = image;
    this._image.mask = this._mask;
    this.addChildAt(this._image, 1);

    this._stage.update();
    if(this._cache){
      this.cache(0, 0, this.componentWidth, this.componentHeight);
    }
  }

  set maskEnabled(yesno) {
    if (this._maskEnabled !== yesno) {

      this._maskEnabled = yesno;

      if (yesno) {
        this._image.mask = this._mask;
        this._image.editable = false;
      } else {
        this._image.mask = null;
        this._image.editable = true;
      }
      this._stage.update();
      if(this._cache){
        this.uncache(); 
      }
    }
  }

  get colSpan() {
    return this._frameDef.colSpan;
  }

  get maskEnabled() {
    return this._maskEnabled;
  }

  set selected(yesno) {
    if (this._selected !== yesno) {
      this._selected = yesno;
      if (this._selected) {
        this._border.shadow = this._selectShadow;
        if(this._cache){
          this.uncache();
        }
      } else {
        this._border.shadow = null;
        if(this._cache){
          this.cache(0, 0, this.componentWidth, this.componentHeight);
        }
      }
      this._stage.update();
    }
  }

  get selected() {
    return this._selected;
  }

  get image() {
    return this._image;
  }

  _setupMask = () => {
    this._mask = new createjs.Shape();
    var mask = this._mask;
    mask.graphics.beginFill("#aaaaaa");
    this._drawFrameShape(mask.graphics);
    this.addChildAt(mask, 0);
  }

  _drawBorder = () => {
    this._border = new createjs.Shape();
    var border = this._border;
    border.graphics.setStrokeStyle(this._borderWeight);
    border.graphics.beginStroke("#000000");
    this._drawFrameShape(border.graphics);
    this.addChild(border);
  }

  _drawFrameShape(graphics) {
    var points = this._frameDef.points;
    for (var i = 0; i < points.length; i++) {
      if (i === 0) {
        graphics.moveTo(points[i].x, points[i].y);
      } else {
        graphics.lineTo(points[i].x, points[i].y);
      }
    }

    graphics.closePath();
  }
}