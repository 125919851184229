

var createjs = window.createjs;

export default class ArtBoard extends createjs.Container{
  constructor(stage){
    super();
    this._stage = stage;
    this._width = 900;
    this._height = 440;
    this._backing = new createjs.Shape();
    this._mask = new createjs.Shape();
    this.addChild(this._backing);

    this._backing.on('click', () => {
      if(this._comicStrip){
        this._comicStrip.removeSelection();
      }
    })

    this._setupDragHandlers(this._backing, this);
    this._drawBacking();
    // this._drawMask();
  }

  setDimensions = (width, height) => {
    this._width = width;
    this._height = height;

    this._drawBacking();
    // this._drawMask();
  }

  set comicStrip(comicStrip){
    if(this._comicStrip){
      this.removeChild(this._comicStrip);
    }

    this._comicStrip = comicStrip;
    // this._comicStrip.mask = this._mask;
    this.addChild(this._comicStrip);
    this.positionComicStrip();
  }

  positionComicStrip = () => {
    var comicStrip = this._comicStrip;

    comicStrip.regX = comicStrip.width / 2;
    comicStrip.regY = comicStrip.height / 2;

    comicStrip.x = (this.width / 2);
    comicStrip.y = (this.height / 2);
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  _drawBacking = () => {
    var backing = this._backing;
    backing.graphics.clear();
    backing.graphics.setStrokeStyle(.5);
    backing.graphics.beginStroke("#999999");
    backing.graphics.beginFill("#ffffff").drawRect(0, 0, this._width, this._height);
  }

  _drawMask = () => {
    var mask = this._mask;
    mask.graphics.beginFill("#ffffff").drawRect(0, 0, this._width, this._height);
  }

  _setupDragHandlers = (trigger, target) => {
    var initialX = 0;
    var initialY = 0;
    var self = this;

    trigger.on("mousedown", function(evt){
      initialX = evt.stageX - target.x;
      initialY = evt.stageY - target.y;
      // Could do this if could calculate bounds outside of the artboard
      // self.cache(0, 0, self._width, self._height);
    });

    trigger.on("pressmove", function(evt){
      target.x = evt.stageX - initialX;
      target.y = evt.stageY - initialY;
      target._stage.update();
    });

    trigger.on("pressup", function(evt){
      // Could do this if could calculate bounds outside of the artboard
      // self.uncache();
    });
  }
}