import React from 'react'
import StripeCheckout from 'react-stripe-checkout';

// https://www.robinwieruch.de/react-express-stripe-payment/

export default class SalesForm extends React.Component {

  constructor(props){
    super(props);
    this.imageDataCallback = props.imageDataCallback;
    this.saveSuccessCallback = props.saveSuccessCallback;
    this.processingCallback = props.processingCallback;
  }

  onToken = (token) => {
    var imageData = this.imageDataCallback();
    this.processingCallback();

    // URL
    const url = "https://us-central1-comic-builder.cloudfunctions.net/payment";
    // const url = "http://localhost:5000/comic-builder/us-central1/payment";

    fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token_id: token.id,
        image: imageData,
        email: token.email
      })
    }).then(response => {
      response.json().then(data => {
        console.log(data);
        if(data.success && data['imageurl'] != undefined){
          this.saveSuccessCallback(data['imageurl']);
        }
      });
    });
  }

  render() {
    return (
      // <StripeCheckout token={this.onToken} />
      <div />
    )
  }
}


