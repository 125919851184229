
var createjs = window.createjs;

export default class BubbleTail extends createjs.Container{
  constructor(stage){
    super();

    this._stage = stage;
    this._tailEndX = 80;
    this._tailEndY = 80;
    this._selected = false;
    this._mouseOver = false;

    this._tail = new createjs.Shape();
    this._dragger = new createjs.Shape();
    this._dragger.visible = false;

    var dragger = this._dragger;
    dragger.graphics.setStrokeStyle(5);
    dragger.graphics.beginStroke('#ff0000');
    dragger.graphics.beginFill('#00000033');
    dragger.graphics.drawCircle(0, 0, 20);
    dragger.x = this.tailEndX;
    dragger.y = this.tailEndY;
    dragger.regx = this.tailEndX;
    dragger.regy = this.tailEndY;

    this._setupDragHandlers(this._dragger);

    this._radius = 150;
    this._theta = 330;
    this._baseHeight = 30;

    this.addChild(this._tail);
    this.addChild(this._dragger);

    this.redraw();
  }

  get theta(){
    return this._theta;
  }

  get tailEndX(){
    return this._tailEndX;
  }

  get tailEndY(){
    return this._tailEndY;
  }

  set tailEndY(value){
    this._tailEndY = value;
    this._dragger.y = this.tailEndY;
    this._dragger.regy = this.tailEndY;
  }

  set tailEndX(value){
    this._tailEndX = value;
    this._dragger.x = this.tailEndX;
    this._dragger.regx = this.tailEndX;
  }

  set selected(yesno){
    if(yesno !== this._selected){
      this._selected = yesno;
      
      if(yesno === true){
        this._dragger.visible = true;
      }else{
        this._dragger.visible = false;
      }

      this.redraw();
      this._stage.update();
    }
  }

  set mouseOver(yesno){
    this._mouseOver = yesno;
    this.redraw();
  }

  get color(){
    if(this.selected){
      return "#ff0000";
    }else if(this._mouseOver){
      return "#ff0000";
    }else{
      return "#000000";
    }
  }

  redraw = () => {
    var tail = this._tail;
    tail.graphics.clear();
    tail.graphics.setStrokeStyle(1);
    tail.graphics.beginStroke(this.color);
    tail.graphics.beginFill('#ffffff');

    var bounds = this._angleBounds(this.tailEndX, this.tailEndY);

    tail.graphics.moveTo(bounds[0].x, bounds[0].y);
    tail.graphics.lineTo(bounds[1].x, bounds[1].y);
    tail.graphics.lineTo(bounds[2].x, bounds[2].y);
    tail.graphics.lineTo(bounds[0].x, bounds[0].y);

  }

  _setupDragHandlers = (shape) => {
    var initialX = 0;
    var initialY = 0;

    var initialXOffset = 0;
    var initialYOffset = 0;

    var self = this;

    shape.on("mousedown", function(evt){
      var point = self.parent.globalToLocal(evt.stageX, evt.stageY);

      initialX = point.x;
      initialY = point.y;

      initialXOffset = self.tailEndX;
      initialYOffset = self.tailEndY;
    });

    shape.on("pressmove", function(evt){

      var point = self.parent.globalToLocal(evt.stageX, evt.stageY);
      
      self._tailEndX = point.x - initialX + initialXOffset;
      self._tailEndY = point.y - initialY + initialYOffset;

      shape.x = self.tailEndX;
      shape.y = self.tailEndY;

      self.redraw();
      self._stage.update();
    });

    shape.on("pressup", function(evt){
    });
  }

  _angleBounds = (headX, headY) => {
    var footLength = 10;
    var degs = Math.atan2(headY, headX) * 180 / Math.PI;
    
    var newAngleDeg = 90 - degs;
    var newAngleRad = newAngleDeg / (180 / Math.PI);

    var foot1Y = footLength * Math.sin(newAngleRad);
    var foot1X = -(footLength * Math.cos(newAngleRad));

    var foot2Y = -foot1Y;
    var foot2X = -foot1X;
    
    return [{x: headX, y: headY}, {x: foot1X, y: foot1Y}, {x: foot2X, y: foot2Y}]
  }
}