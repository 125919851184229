import TextOverlay from './TextOverlay.js';

var createjs = window.createjs;

export default class RectBubble extends TextOverlay {
  constructor(stage, color){
    super(stage);

    this._stage = stage;

    if(color){
      this._backingColor = color;
    }else{
      this._backingColor = "#ffffff";
    }
    
    this._minWidth = 100;
    this._minHeight = 25;

    this._backing = new createjs.Shape();
    this.body.addChildAt(this._backing, 0);

    this.redraw();
  }

  redraw = () => {
    this.computeTextBounds();
    this.drawText();
    
    var backing = this._backing;
    backing.graphics.clear();
    backing.graphics.setStrokeStyle(1);
    backing.graphics.beginStroke(this.color);
    backing.graphics.beginFill(this._backingColor);

    var computedHeight = this.textHeight + 20;
    var computedWidth = Math.max(100, this.textWidth + 20);

    if(this._desiredWidth){
      this._componentWidth = this._desiredWidth;
      this._text.lineWidth = this._desiredWidth - 20;
    }else{
      this._componentWidth = computedWidth;
    }

    if(this._desiredHeight && this._desiredHeight > this.textHeight){
      this._componentHeight = this._desiredHeight;
    }else{
      this._componentHeight = computedHeight;
    }

    var rectTopY = -(this.componentHeight / 2);
    var rectTopX = -(this.componentWidth / 2);

    backing.y = rectTopY;
    backing.x = rectTopX;

    backing.graphics.drawRect(0, 0, this.componentWidth, this.componentHeight);

    this.drawResizeDragger();
    this._stage.update();
  }

  drawResizeDragger = () => {
    var resizeDragger = this._resizeDragger;
    resizeDragger.graphics.beginFill('#ff0000');
    resizeDragger.graphics.drawRect(0, 0, 10, 10);

    resizeDragger.x = this.componentWidth / 2 + 5;
    resizeDragger.y = this.componentHeight / 2 + 5;
  }
}