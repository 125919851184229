import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import SalesForm from './SalesForm';

export default class SalesPitch extends React.Component {
  constructor(props) {
    super(props);
    this.imageDataCallback = props.imageDataCallback;
    this.state = { show: false, paid: false, imageurl: undefined, processing: false };
  }

  componentDidMount() {

  }

  _handleSaveSuccess = (imageurl) => {
    this.setState({paid: true, processing: false, imageurl: imageurl})
  }

  _handleProcessingStart = () => {
    this.setState({ processing: true });
  }

  _hideForm = () => {
  }

  _processing = () => {
    if (this.state.processing) {
      return (
        <h4>Processing... please wait</h4>
      );
    } else {
      return (
        <div>
          <SalesForm saveSuccessCallback={this._handleSaveSuccess} processingCallback={this._handleProcessingStart} imageDataCallback={this.imageDataCallback} />
          <Button className="ml-2" variant="brandred" onClick={() => this.setState({ show: false })}>Not Today</Button>

          {/* <Button className="ml-2" onClick={() => this.state.saveSuccessCallback()}> Save Without Watermark</Button> */}
        </div>
      );
    }
  }

  _resetBuilder = () => {
    this.setState({ show: false, paid: false, imageurl: undefined, processing: false })
  }

  _comicImage = () => {
    // if(this.state.imageurl){
    //   return (
    //     <img src={this.state.imageurl} width="50%" />
    //   );
    // }else{
    return (
      <img src="images/withoutWatermarkComic.png" width="50%" />
    );
    // }
  }

  _modalContent = () => {
    if (this.state.paid === false) {
      return (
        <div>
          <div>
            <h3>
              You can export a comic without a watermark for $2.00.
            </h3>

            When you pay we will:
            <ul>
              <li>
                Send you an email containing a link to your comic.
              </li>
              <li>
                Show you a button you can click to download your comic.
              </li>
            </ul>
          </div>

          Questions? Feel free to <a href="mailto:support@wildnotion.com" target="_top">send us an email</a>.
            <hr />

          {this._processing()}
        </div>
      );
    } else {
      return (
        <div>
          <Button className="ml-2" size="lg" href={this.state.imageurl} target='_blank' variant="brandred">Download Watermark-free Comic</Button>
          <Button className="ml-2" variant="brandred" onClick={() => this._resetBuilder()}>Back to the Builder</Button>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Button variant="brandred" className='mt-2' onClick={() => this.setState({ show: true })}>Without Watermark</Button>

        <Modal
          size="lg"
          onHide={this._hideForm}
          show={this.state.show}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="m-4">
            {this._comicImage()}
            <hr />

            {this._modalContent()}

          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
