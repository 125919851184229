var createjs = window.createjs;
var fx = window.fx;

export default class ComicImage extends createjs.Container{
  constructor(stage, image, targetWidth, targetHeight){
    super();

    this._stage = stage;
    this._componentWidth = targetWidth;
    this._componentHeight = targetHeight;
    this._onload = null;
    this._brightness = .1;
    this._contrast = .1;
    this._image = image;
    this._canvas = null;
    this._texture = null;
    this._editable = false;

    this.imageWidth = 0;
    this.imageHeight = 0;

    this._setupResizeControl();
    this.initImage();
  }

  drawBackground = () => {
    var background = new createjs.Shape();
    background.graphics.beginFill("#aaaaaa").drawRect(0, 0, this.componentWidth, this.componentHeight);
    this.addChildAt(background, 0);
  }

  initImage = () => {
    if(this.bmp){
      this.removeChild(this.bmp);
      this.bmp = null;
    }

    var loadBMP = new createjs.Bitmap(this._image);
    var bmp = loadBMP;

    var minScaleFactor = this._getMinScaleFactor(bmp.image, this._componentWidth, this._componentHeight) * 2;
    bmp.cache(0, 0, bmp.image.width, bmp.image.height, minScaleFactor);

    this._canvas = fx.canvas();
    this._texture = this._canvas.texture(bmp.cacheCanvas);
    this._canvas.draw(this._texture).brightnessContrast(this._brightness, this._contrast).update();

    this.bmp = new createjs.Bitmap(this._canvas);

    this.addChildAt(this.bmp, 0);

    this._loaded = true;
    this.resizeDragger.visible = true;
    this.scaleBmp(this._componentWidth, this._componentHeight);
    this._setupDragHandlers();
  }

  set editable(yesno){
    this._editable = yesno;
    if(yesno){
      this.resizeDragger.visible = true;
    }else{
      this.resizeDragger.visible = false;
    }
    this._stage.update();
  }

  get editable(){
    return this._editable;
  }

  set brightness(value){
    if(this._brightness !== value){
      this._brightness = value;
      this._stage.update();
    }
  }

  get brightness(){
    return this._brightness;
  }

  set contrast(value){
    if(this._contrast !== value){
      this._contrast = value;
      this._stage.update();
    }
  }

  get contrast(){
    return this._contrast;
  }

  set onload(callback){
    this._onload = callback;
  }

  set src(imageData){
    var image = new Image();
    image.onload = (event) => {
      this._image = image;

      this.initImage();
    }
    image.src = imageData;
  }

  set componentWidth(componentWidth){
    this._componentWidth = componentWidth;
  }

  get componentWidth(){
    return this._componentWidth;
  }

  get componentHeight(){
    return this._componentHeight;
  }

  set componentHeight(componentHeight){
    this._componentHeight = componentHeight;
  }

  draw = (ctx, ignoreCache) => {
    this._canvas.draw(this._texture).brightnessContrast(this._brightness, this._contrast).update();
    return super.draw(ctx, ignoreCache);
  }

  scaleBmp = (width, height) => {
    if(this.bmp.image !== undefined){
      var minScaleFactor = this._getMinScaleFactor(this.bmp.image, width, height);

      this.imageWidth = this.bmp.image.width * minScaleFactor;
      this.imageHeight = this.bmp.image.height * minScaleFactor; 

      this.componentWidth = this.imageWidth;
      this.componentHeight = this.imageHeight;

      this.resizeDragger.x = this.imageWidth - 25;
      this.resizeDragger.y = this.imageHeight - 25;

      this.bmp.scale = minScaleFactor;
      this._stage.update();
    }
  }

  _getMinScaleFactor = (image, width, height) => {
    var scaleXFactor = width / image.width;
    var scaleYFactor = height / image.height;

    var minScaleFactor = Math.max(scaleXFactor, scaleYFactor);
    return minScaleFactor;
  }

  _setupResizeControl = () => {
    this.resizeDragger = new createjs.Shape();
    this.resizeDragger.visible = false;
    var resizeDragger = this.resizeDragger;
    resizeDragger.graphics.beginFill("#ff0000").drawRect(0, 0, 25, 25);

    this.addChild(resizeDragger);

    var initialX = 0;
    var initialY = 0;
    var initialComponentHeight = 0;
    var initialComponentWidth = 0;
    var self = this;

    resizeDragger.on("mousedown", function(evt){
      var point = self.parent.globalToLocal(evt.stageX, evt.stageY);
      initialX = point.x;
      initialY = point.y;
      initialComponentHeight = self.componentHeight;
      initialComponentWidth = self.componentWidth;
    });

    resizeDragger.on("pressmove", function(evt){
      var point = self.parent.globalToLocal(evt.stageX, evt.stageY);
      var newHeight = initialComponentHeight - (initialY - point.y);
      var newWidth = initialComponentWidth - (initialX - point.x);

      self.componentWidth = newWidth;
      self.componentHeight = newHeight;

      resizeDragger.x = self.imageWidth - 25;
      resizeDragger.y = self.imageHeight - 25;

      self.scaleBmp(newWidth, newHeight);  
    });

    resizeDragger.on("pressup", function(evt){
      
    });
  }

  _setupDragHandlers = () => {
    var initialX = 0;
    var initialY = 0;
    var bmp = this.bmp;
    var self = this;

    bmp.on("mousedown", function(evt){
      if(self.editable){
        var point = self.parent.globalToLocal(evt.stageX, evt.stageY);
        initialX = point.x - self.x;
        initialY = point.y - self.y;
      }
    });

    bmp.on("pressmove", function(evt){
      if(self.editable){
        var point = self.parent.globalToLocal(evt.stageX, evt.stageY);
        bmp.alpha = .5;
        self.x = point.x - initialX;
        self.y = point.y - initialY;
        self._stage.update();
      }
    });

    bmp.on("pressup", function(evt){
      if(self.editable){
        bmp.alpha = 1;
        self._stage.update();
      }
    });
  }
}