import RectBubble from './RectBubble.js';
import ComicBubble from './ComicBubble.js';
import WaterMark from './WaterMark.js';

var createjs = window.createjs;

export default class ComicStrip extends createjs.Container{
  constructor(stage, rows, cols){
    super();
    this._stage = stage;
    this._frames = [];
    this._overlays = [];
    this._paddingX = 10;
    this._paddingY = 10;
    this._rows = rows;
    this._cols = cols;
    this._width = 0;
    this._height = 0;
    this._selectedFrame = null;
    this._selectedOverlay = null;
    this._currentFrameCount = 0;

    this._watermark = new WaterMark(stage);

    this._nextFrameX = 0;
    this._nextFrameY = 0;

    this._backing = new createjs.Shape();
    this._backing.shadow = new createjs.Shadow("#000000", 0, 0, 4);

    this._mainLayer = new createjs.Container();
    this._overlayLayer = new createjs.Container();

    this.addChild(this._backing);
    this.addChild(this._mainLayer);
    this.addChild(this._overlayLayer);
    this.addChild(this._watermark);
  }

  setDimensions = (rows, cols) => {
    this._rows = rows;
    this._cols = cols;
  }

  addFrame = (frame) => {
    // frame.x = Math.floor((this._currentFrameCount % this._cols)) * (frame.componentWidth + this._paddingX);
    // frame.y = Math.floor((this._currentFrameCount / this._cols)) * (frame.componentHeight + this._paddingY);

    frame.x = this._nextFrameX;
    frame.y = this._nextFrameY;

    if(this._currentFrameCount === 0){
      this._width += frame.componentWidth;
      this._height += frame.componentHeight;
    }else if(this._currentFrameCount % this._cols === 0){
      this._height += frame.componentHeight + this._paddingY;
    }else if(this._height === frame.componentHeight){
      this._width += frame.componentWidth + this._paddingX;
    }

    if((this._currentFrameCount + frame.colSpan) % this._cols === 0){
      // New row
      this._nextFrameX = 0;
      this._nextFrameY = this._nextFrameY + this._paddingY + frame.componentHeight;
    } else {
      // New col
      this._nextFrameX = this._nextFrameX + this._paddingX + frame.componentWidth;
    }

    frame.addEventListener("click", this._handleFrameClick);
    this._frames.push(frame);

    this._currentFrameCount += frame.colSpan;

    this._mainLayer.addChild(frame);
    this._watermark.y = this._height - 30;
    this._watermark.x = this._width - (this._watermark.componentWidth + 10);
    this._updateComicBacking();
    this._stage.update();
  }

  removeOverlay = (overlay) => {
    var index = this._overlays.indexOf(overlay);
    if (index > -1) {
      this._overlays.splice(index, 1);
      this._overlayLayer.removeChild(overlay);
      this._stage.update(); 
    }
  }

  removeAllFrames = () => {
    do{
      var frame = this._frames.pop();
      if(frame){
        this._mainLayer.removeChild(frame);
      }
    }while(frame);
    this.removeSelection();
    this._currentFrameCount = 0;
    this._nextFrameX = 0;
    this._nextFrameY = 0;
    this._width = 0;
    this._height = 0;
  }

  editFrame = (index) => {
    this.frames[index].maskEnabled = false;
    this._mainLayer.setChildIndex(this.frames[index], this.frames.length - 1);
  }

  addBubble = () => {
    var newBubble = new ComicBubble(this._stage);
    newBubble.x = Math.random() * 100 - 50;
    newBubble.y = Math.random() * 100 - 50;
    this._addOverlay(newBubble);
    return newBubble;
  }

  addCaption = (color) => {
    var caption = new RectBubble(this._stage, color);
    caption.x = Math.random() * 100 - 50;
    caption.y = Math.random() * 100 - 50;
    this._addOverlay(caption);
    return caption;
  }

  hideWatermark = () => {
    this._watermark.visible = false;
  }

  showWatermark = () => {
    this._watermark.visible = true;
  }

  get width(){
    return this._width;
  }

  get height(){
    return this._height;
  }

  get frames(){
    return this._frames;
  }

  get frameCount(){
    return this._frames.length;
  }

  removeSelection = () => {
    this._handleSetOverlay(null);
    this._handleSetSelectedFrame(null);
    let selectEvent = new Event("clearSelection");
    this.dispatchEvent(selectEvent);
  }

  setSelectedFrame = (index) => {
    this._handleSetSelectedFrame(this.frames[index])
  }

  get selectedFrame(){
    return this._selectedFrame;
  }

  get selectedOverlay(){
    return this._selectedOverlay;
  }

  get bitmapData(){
    var overlapX = this._paddingX * 2;
    var overlapY = this._paddingY * 2;

    var objectMinXs = this._overlays.map(overlay => overlay.x - overlay.componentWidth / 2);
    objectMinXs.push(-this._paddingX);
    var minX = Math.min.apply(null, objectMinXs) - this._paddingX;

    var objectMinYs = this._overlays.map(overlay => overlay.y - overlay.componentHeight / 2);
    objectMinYs.push(-this._paddingY);
    var minY = Math.min.apply(null, objectMinYs) - this._paddingY;

    var objectMaxXs = this._overlays.map(overlay => overlay.x + overlay.componentWidth / 2 - minX);
    objectMaxXs.push(this.width - minX);
    var maxX = Math.max.apply(null, objectMaxXs) + 2 * this._paddingX;

    var objectMaxYs = this._overlays.map(overlay => overlay.y + overlay.componentHeight / 2 - minY);
    objectMaxYs.push(this.height - minY);
    var maxY = Math.max.apply(null, objectMaxYs) + 2 * this._paddingY;

    this.cache(minX, minY, maxX, maxY);

    var url = this.bitmapCache.getCacheDataURL();
    this.uncache();
    return url;
  }

  _addOverlay = (overlay) => {
    overlay.addEventListener("click", this._handleOverlayClick);
    this._overlays.push(overlay);
    this._overlayLayer.addChild(overlay);
    this._stage.update();
  }

  _updateComicBacking = () => {
    var backing = this._backing;
    backing.graphics.clear();
    backing.graphics.beginFill("#ffffff").drawRect(-this._paddingX, -this._paddingY, this.width + this._paddingX * 2, this.height + this._paddingY * 2);
  }

  _handleFrameClick = (event) => {
    this._handleSetSelectedFrame(event.currentTarget);
    this._handleSetOverlay(null);
    let selectEvent = new Event("frameSelected", {frame: event.currentTarget});
    this.dispatchEvent(selectEvent);
  }

  _handleOverlayClick = (event) => {
    this._handleSetOverlay(event.currentTarget);
    this._handleSetSelectedFrame(null);
    let selectEvent = new Event("overlaySelected", {overlay: event.currentTarget});
    this.dispatchEvent(selectEvent);
  }

  _handleSetSelectedFrame(frame){
    for(var i = 0; i < this._frames.length; i++){
      if(frame === this._frames[i]){
        this._frames[i].selected = true;
        this._selectedFrame = this._frames[i];
        this._mainLayer.setChildIndex(this._selectedFrame, this._frames.length - 1);
      }else{
        this._frames[i].selected = false;
        this._frames[i].maskEnabled = true;
      }
    }
  }

  _handleSetOverlay = (overlay) => {
    for(var i = 0; i < this._overlays.length; i++){
      if(overlay === this._overlays[i]){
        this._overlays[i].selected = true;
        this._selectedOverlay = overlay;
      }else{
        this._overlays[i].selected = false;
      }
    }
  }
}